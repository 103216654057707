
export default {
  "no-access-default": {
    title: () => "Du verkar inte ha tillgång",
    description: () => "Vill du använda MyBusiness? Vänd dig till den som administrerar kontot för ditt företag och be om att bli inbjuden som användare.",
    "button-text": () => "Tillbaka till inloggningsidan"
  },
  "no-access-bank-id": {
    title: () => "Hej! Ditt BankID är ännu inte kopplat till MyBusiness",
    preamble: () => "Välj vad du vill göra nedan.",
    logoutText: () => "Om du vill ha tillgång till ditt företags befintliga konto vänder du dig till din administratör för en inbjudan.",
    logoutLinkText: () => "Logga ut",
    "card-bankid": {
      title: () => "Koppla ditt konto till BankID",
      description: () => "Logga in med användarnamn och lösenord. Inne i MyBusiness byter du inloggningsmetod under Inställningar.",
      buttonText: () => "Byta till BankID"
    },
    "card-get-mybusiness": {
      title: () => "Skaffa MyBusiness till företaget",
      description: () => "Har ditt företag ännu inte tillgång till MyBusiness? Skaffa full överblick och smidig hantering av dina tjänster hos oss.",
      buttonText: () => "Skaffa MyBusiness"
    }
  },
  "no-access-cots": {
    title: () => "Du verkar inte ha tillgång",
    description: () => "Du har inte rättighet att imitera den här kunden."
  },
  "logout-button": {
    text: () => "Logga ut",
    loading: () => "Loggar ut..."
  }
}