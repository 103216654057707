import React from "react";
import styled from "styled-components";
import { B2xCard } from "@telia/b2x-card/react-cjs";
import { CtaLinkVariantType } from "@teliads/components/dist/types/types/CtaLinkVariant";
import { spacing } from "@teliads/components/foundations";
import { TeliaCtaLink, TeliaHeading, TeliaP } from "@teliads/components/react";

type CardProps = {
  heading: string;
  content: string;
  buttonText: string;
  ctaVariant: CtaLinkVariantType;
  onButtonClick: () => void;
};

const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing.spacing32};
  padding: ${spacing.spacing24};
  min-height: 21rem;
`;

function Card({ heading, content, ctaVariant, buttonText, onButtonClick }: CardProps) {
  return (
    <B2xCard backgroundColor="light">
      <ContentContainer>
        <TeliaHeading tag="h3" variant="title-200">
          {heading}
        </TeliaHeading>
        <TeliaP>{content}</TeliaP>

        <TeliaCtaLink variant={ctaVariant} onClick={onButtonClick} fullWidth tabIndex={0}>
          {buttonText}
        </TeliaCtaLink>
      </ContentContainer>
    </B2xCard>
  );
}

export default Card;
