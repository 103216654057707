
export default {
  "no-access-default": {
    title: () => "You don't seem to have access",
    description: () => "Do you want to use MyBusiness? Contact the person who administers the account for your company and ask to be invited as a user.",
    "button-text": () => "Tillbaka till inloggningsidan"
  },
  "no-access-bank-id": {
    title: () => "Hi! Your BankID is not yet linked to MyBusiness",
    preamble: () => "Choose what you want to do below.",
    logoutText: () => "If you want access to your company's existing account, contact your administrator for an invitation.",
    logoutLinkText: () => "Log out",
    "card-bankid": {
      title: () => "Link your account to BankID",
      description: () => "Log in with username and password and change the login method under Settings in MyBusiness.",
      buttonText: () => "Change to BankID"
    },
    "card-get-mybusiness": {
      title: () => "Get access for your company",
      description: () => "Doesn't your company have access to MyBusiness yet? Get full overview and smooth handling of your services with us.",
      buttonText: () => "Get access to MyBusiness"
    }
  },
  "no-access-cots": {
    title: () => "You don't seem to have access",
    description: () => "You don't have access to imitate this customer."
  },
  "logout-button": {
    text: () => "Log out",
    loading: () => "Logging out..."
  }
}