import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { corpLogin } from "@telia/b2b-rest-client";
import { AuthInfoDTO } from "@telia/b2b-rest-client/dist/corp-login";
import { B2B_GENERAL_ERROR_URL } from "@telia/b2b-utils";
import { logError } from "@telia/b2x-logging";

export enum Page {
  COTS,
  BANK_ID,
  DEFAULT,
}

export function usePage(): UseQueryResult<Page> {
  return useQuery<Page>({ queryKey: ["no-access-page"], queryFn: resolvePage });
}

function resolvePage(): Promise<Page> {
  const url = new URL(window.location.href);
  if (shouldDisplayCotsNoAccess(url)) {
    return Promise.resolve(Page.COTS);
  }

  return isBankIdAuthentication().then((bankId) => {
    if (bankId) {
      return Page.BANK_ID;
    } else {
      return Page.DEFAULT;
    }
  });
}

function shouldDisplayCotsNoAccess(url: URL): boolean {
  return url.searchParams.get("imitable") === "false";
}

function isBankIdAuthentication(): Promise<boolean> {
  return corpLogin.IsLoggedInControllerService.isLoggedIn()
    .then((authMode: AuthInfoDTO) => {
      if (authMode.authMethods?.length) {
        return authMode.authMethods[0] === "BANKID";
      }
      return false;
    })
    .catch((error) => {
      logError("b2b-no-access", `An error occurred while checking Authentication method. ${error}`);
      window.location.assign(B2B_GENERAL_ERROR_URL);
      return false;
    });
}
