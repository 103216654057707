import styled from "styled-components";
import { breakpoints, spacing } from "@teliads/components/foundations";
import { TeliaCol, TeliaHeading, TeliaIcon, TeliaRow } from "@teliads/components/react";

export const TeliaRowCentered = styled(TeliaRow)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.spacing16};
  @media screen and (min-width: ${breakpoints.breakpointMd}) {
    flex-direction: row;
    justify-content: center;
  }
`;
export const LinkAndTextContainer = styled(TeliaCol)`
  padding-top: ${spacing.spacing16};
  gap: ${spacing.spacing16};
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;
export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;

export const LogoutIcon = styled(TeliaIcon)`
  margin-right: ${spacing.spacing4};
`;

export const HeadingWithSpacing = styled(TeliaHeading)`
  padding-bottom: ${spacing.spacing16};
`;
