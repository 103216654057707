import React from "react";

import { NoAccessBankId } from "./Pages/BankId";
import { NoAccessCots } from "./Pages/Cots";
import { NoAccessDefault } from "./Pages/Default";
import { NoAccessSkeleton } from "./Pages/Skeleton";
import { Page, usePage } from "../hooks/usePage";

export function App() {
  const { data: page } = usePage();

  switch (page) {
    case undefined:
      return <NoAccessSkeleton />;
    case Page.BANK_ID:
      return <NoAccessBankId />;
    case Page.COTS:
      return <NoAccessCots />;
    default:
      return <NoAccessDefault />;
  }
}
