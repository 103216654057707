import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { breakpoints } from "@teliads/components/foundations";
import colors from "@teliads/components/foundations/colors/variables.js";
import spacing from "@teliads/components/foundations/spacing/variables.js";
import {
  TeliaCol,
  TeliaGrid,
  TeliaHeading,
  TeliaRow,
  TeliaTextSpacing,
} from "@teliads/components/react";

import UserAdminSvg from "../../../assets/graphics/user-admin.svg";
import { Description } from "../Description";

export const AlertContainer = styled.div`
  padding-top: ${spacing.spacing96};
  padding-bottom: ${spacing.spacing96};
  @media screen and (min-width: ${breakpoints.breakpointSm}) {
  }
`;

export const TeliaGridFlex = styled(TeliaGrid)`
  display: flex;
  flex-direction: column;
`;
export const TeliaRowSpaced = styled(TeliaRow)`
  padding-top: ${spacing.spacing24};
  padding-bottom: ${spacing.spacing24};
`;
export const TeliaColCentered = styled(TeliaCol)`
  width: 90%;
  margin: auto;
  text-align: center;
`;

interface LayoutProps {
  title: string;
  alertMessageList: string[];
}
export function Layout(props: PropsWithChildren<LayoutProps>) {
  const { title, alertMessageList, children } = props;
  return (
    <div
      data-testid="b2b-no-access-page-root"
      id="noAccessPage"
      style={{ backgroundColor: colors.gray50 }}
    >
      <AlertContainer>
        <TeliaGridFlex>
          <TeliaRowSpaced>
            <TeliaColCentered>
              <UserAdminSvg />
            </TeliaColCentered>
          </TeliaRowSpaced>
          <TeliaRowSpaced>
            <TeliaColCentered>
              <TeliaTextSpacing>
                <TeliaHeading tag="h2" variant="title-400">
                  {title}
                </TeliaHeading>
              </TeliaTextSpacing>
            </TeliaColCentered>
          </TeliaRowSpaced>
          {alertMessageList.map((desc, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Description description={desc} dataTestId={"desc-" + index} key={"desc-" + index} />
          ))}
          {children}
        </TeliaGridFlex>
      </AlertContainer>
    </div>
  );
}
