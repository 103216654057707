import React from "react";
import { useMessage } from "@messageformat/react";

import { Layout } from "../../Layout";

export function NoAccessCots() {
  const title = useMessage("no-access-cots.title");
  const description = useMessage("no-access-cots.description");
  return <Layout title={title} alertMessageList={[description]} />;
}
