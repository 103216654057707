import React, { useCallback, useState } from "react";
import { useMessage } from "@messageformat/react";
import { logoutCurrentUser } from "@telia/b2b-corp-login";
import { B2B_START_PAGE_URL } from "@telia/b2b-utils";
import { TeliaCtaLink } from "@teliads/components/react";

import { TeliaColCentered, TeliaRowSpaced } from "../Layout";

type LogoutButtonProps = {
  buttonLabels?: {
    text: string;
    loadingText: string;
  };
};

export function LogoutButton({ buttonLabels }: LogoutButtonProps) {
  const [invokingLogout, setInvokingLogout] = useState<boolean>(false);
  const buttonTextDefault = useMessage("logout-button.text");
  const buttonTextLoadingDefault = useMessage("logout-button.loading");

  const buttonText = buttonLabels?.text ? buttonLabels.text : buttonTextDefault;
  const buttonTextLoading = buttonLabels?.loadingText
    ? buttonLabels.loadingText
    : buttonTextLoadingDefault;

  const pageUrl = new URL(window.location.href);

  const handleButtonClick = useCallback((fallbackUrl: URL) => {
    setInvokingLogout(true);
    logoutCurrentUser().then((returnUrl) => {
      if (returnUrl) {
        window.location.assign(returnUrl);
      } else {
        window.location.assign(getReturnUrl(fallbackUrl));
      }
    });
  }, []);
  const getButtonText = (): string => {
    return invokingLogout ? buttonTextLoading : buttonText;
  };

  return (
    <TeliaRowSpaced>
      <TeliaColCentered>
        <TeliaCtaLink
          tabIndex={0}
          data-testid="no-access-return-button"
          variant="primary"
          onClick={() => handleButtonClick(pageUrl)}
        >
          {getButtonText()}
        </TeliaCtaLink>
      </TeliaColCentered>
    </TeliaRowSpaced>
  );
}

const getReturnUrl = (url: URL): string => {
  // Check if invoker specified a return_url
  let returnUrl = url.searchParams.get("return_url");

  if (!returnUrl) {
    returnUrl = `${url.origin}${B2B_START_PAGE_URL}`;
  }

  return returnUrl;
};
