import { MessageObject } from "@messageformat/react";
import { DefinedUseQueryResult, useQuery } from "@tanstack/react-query";
import { currentLanguage } from "@telia/b2b-i18n";
import en from "../locales/locale.en.yaml";
import sv from "../locales/locale.sv.yaml";

export function useMessages(): DefinedUseQueryResult<MessageObject | null> {
  const lang = currentLanguage();

  return useQuery<MessageObject | null>({
    queryKey: ["messages", lang],
    queryFn: () => messageWorker(lang),
    enabled: !!lang,
    initialData: null,
  });
}

function messageWorker(lang: string): Promise<MessageObject> {
  if (lang === "en") {
    return en;
  } else {
    return sv;
  }
}
