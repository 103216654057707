import React from "react";
import { useMessage } from "@messageformat/react";

import { Layout } from "../../Layout";
import { LogoutButton } from "../../LogoutButton";

export function NoAccessDefault() {
  const title = useMessage("no-access-default.title");
  const description = useMessage("no-access-default.description");
  return (
    <Layout title={title} alertMessageList={[description]}>
      <LogoutButton />
    </Layout>
  );
}
