import React from "react";
import { TeliaP, TeliaTextSpacing } from "@teliads/components/react";

import { TeliaColCentered, TeliaRowSpaced } from "../Layout";

interface NoAccessBaseDescriptionProps {
  description: string;
  dataTestId: string;
}

export function Description({ description, dataTestId }: NoAccessBaseDescriptionProps) {
  return (
    <TeliaRowSpaced>
      <TeliaColCentered>
        <TeliaTextSpacing>
          <TeliaP data-testid={dataTestId} variant="preamble-100">
            {description}
          </TeliaP>
        </TeliaTextSpacing>
      </TeliaColCentered>
    </TeliaRowSpaced>
  );
}
