import React from "react";
import { TeliaSkeleton } from "@teliads/components/react";

export function NoAccessSkeleton() {
  return (
    <div style={{ height: "48rem", width: "100%", marginBottom: "2rem" }}>
      <TeliaSkeleton style={{ height: "100%", width: "100%" }} />
    </div>
  );
}
