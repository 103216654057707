import React, { PropsWithChildren } from "react";
import { MessageProvider } from "@messageformat/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { currentLanguage } from "@telia/b2b-i18n";
import { registerIconsBundle } from "@teliads/icons";

import { App } from "../App";
import { NoAccessSkeleton } from "../Pages/Skeleton";
import { useMessages } from "../../hooks/useMessages";

export function Scaffolding() {
  registerIconsBundle();
  const reactQueryClient = new QueryClient();

  return (
    <QueryClientProvider client={reactQueryClient}>
      <NoAccessMessageProvider>
        <App />;
      </NoAccessMessageProvider>
    </QueryClientProvider>
  );
}

function NoAccessMessageProvider({ children }: PropsWithChildren<unknown>) {
  const { data: messages } = useMessages();
  if (!messages) {
    return <NoAccessSkeleton />;
  }

  return (
    <MessageProvider locale={currentLanguage()} messages={messages}>
      {children}
    </MessageProvider>
  );
}
